/* eslint-disable */
import React from "react";
import logo from "../../logo.svg";
import "./navbar.css";

const Navbar = () => {
  return (
    <div className="spf__navbar">
      <div className="spf__navbar-links_logo">
        <img src={logo} />
      </div>
    </div>
  );
};

export default Navbar;
