import React from "react";

const Card = ({ title, message, style }) => (
  <article
    style={{
      backgroundColor: "white",
      width: "100%",
      border: "none",
      borderRadius: 4,
      overflow: "hidden",
      margin: "1rem 0",
      padding: "1.25rem",
      minHeight: "12rem",
      fontFamily: "sans-serif",
      ...style,
    }}
  >
    {title && (
      <>
        <h4
          style={{
            fontSize: "1.25rem",
            marginBottom: "1rem",
            letterSpacing: 0.8,
          }}
        >
          {title}
        </h4>
        <div
          style={{
            width: "100%",
            height: 1,
            backgroundColor: "gray",
            marginBottom: "1.25rem",
          }}
        />
      </>
    )}
    <p
      style={{ fontSize: "1rem" }}
      dangerouslySetInnerHTML={{ __html: message }}
    />
  </article>
);

export default Card;
