import React from "react";
import ReactDOM from "react-dom";
import { initializeApp } from "firebase/app";
import { initializeFirestore } from "firebase/firestore";

import App from "./App";

import "./index.css";
import { __sretanCollection__ } from "./constants";

const firebaseConfig = {
  apiKey: "AIzaSyBcnKLSFK5kzEE1sUtFG0fDiEja_IlCx8k",
  authDomain: "sasa-sretan-rodjendan.firebaseapp.com",
  projectId: "sasa-sretan-rodjendan",
  storageBucket: "sasa-sretan-rodjendan.appspot.com",
  messagingSenderId: "843107906749",
  appId: "1:843107906749:web:1429be7e389e120870b3d4",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig, __sretanCollection__);
// Initialize Firestore
initializeFirestore(app, {});

ReactDOM.render(<App />, document.getElementById("root"));
