import React from "react";

const UnderConstruction = () => (
  <div
    style={{
      width: "60%",
      margin: "0 auto",
      padding: "1rem",
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      color: "white",
      fontFamily: "sans-serif",
    }}
  >
    <h1 style={{ fontSize: "3.5rem", marginBottom: "3.5rem" }}>
      Under Maintenance
    </h1>
    <p style={{ fontSize: "1.75rem", textAlign: "right" }}>
      Parts of our site are currently under maintenance. Stay tuned...
    </p>
  </div>
);

export default UnderConstruction;
