/* eslint-disable quotes */
import React from "react";
import { HashRouter, Route, Routes } from "react-router-dom";

import { Navbar } from "./components";
import PostSretanRodjendan from "./PostSretanRodjendan";
import GetSretanRodjendan from "./GetSretanRodjendan";
import UnderConstruction from "./UnderConstruction";

import "./App.css";

const App = () => (
  <div className="App">
    <div className="gradient__bg">
      <Navbar />
    </div>
    <HashRouter hashType="slash">
      <Routes>
        <Route path="send-birthday-card" element={<PostSretanRodjendan />} />
        <Route path="birthday-cards" element={<GetSretanRodjendan />} />
        <Route path="/" exact element={<UnderConstruction />} />
      </Routes>
    </HashRouter>
  </div>
);

export default App;
