import React, { useState } from "react";
import { getApp } from "firebase/app";
import { getFirestore, onSnapshot, collection } from "firebase/firestore";

import { __sretanCollection__ } from "./constants";

import Card from "./Card";

const useSasaSretanRodjendan = () => {
  // state containing all "sasa sretan rodjendan" cards
  const [cards, setCards] = useState([]);

  // reference to `sasa-sretan-rodjendan` collection in firestore db
  const sretanRef = collection(
    getFirestore(getApp(__sretanCollection__)),
    // eslint-disable-next-line comma-dangle
    __sretanCollection__
  );

  // on each new "sretan rodjendan" this will get updated in firestore
  // and the updates will get sent back to client through this subscription
  onSnapshot(sretanRef, (collRef) => {
    const orderedDocs = collRef.docs
      .map((docRef) => docRef.data())
      .sort((a, b) => (a.timestamp < b.timestamp ? 1 : -1));
    setCards(orderedDocs);
  });

  return cards;
};

const GetSretanRodjendan = () => {
  const cards = useSasaSretanRodjendan();

  return (
    <div
      style={{
        width: "80%",
        margin: "0 auto",
        padding: "1rem",
      }}
    >
      <h1
        style={{ color: "white", fontFamily: "sans-serif", margin: "2rem 0" }}
      >
        Happy Birthday
      </h1>
      {cards.map((cardProps) => (
        // eslint-disable-next-line react/jsx-props-no-spreading
        <Card {...cardProps} />
      ))}
    </div>
  );
};

export default GetSretanRodjendan;
