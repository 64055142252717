import React, { useState } from "react";
import { Formik, Form, useField } from "formik";
import { getApp } from "firebase/app";
import { getFirestore, collection, addDoc } from "firebase/firestore";

import { __sretanCollection__ } from "./constants";

import Card from "./Card";

const successMessage = "Message successfully sent";

const sendingMessage = "Sending...";

// eslint-disable-next-line operator-linebreak
const errorMessage =
  "Oops... There seems to be an error with this form. Please refresh and try again. If the form still doesn't work, we're already fixing it. You can still send you thoughts on <a style='color:blue;text-decoration:underline' href='mailto:sasa@sasapetar.com?subject=60th birthday card'>sasa@sasapetar.com</a>";

const PostSretanRodjendan = () => {
  const initialValues = { title: "", message: "" };

  const [dialogMessage, setDialogMessage] = useState("");

  const onSubmit = async (rawCard) => {
    const sretanRef = collection(
      getFirestore(getApp(__sretanCollection__)),
      // eslint-disable-next-line comma-dangle
      __sretanCollection__
    );

    const timestamp = Date.now().toString();
    const card = { ...rawCard, timestamp };

    try {
      setDialogMessage(sendingMessage);
      // add new "sretan rodjendan" to firestore
      await addDoc(sretanRef, card);
      // return success result
      setDialogMessage(successMessage);
    } catch {
      setDialogMessage(errorMessage);
    }
  };

  return (
    <Formik {...{ initialValues, onSubmit }}>
      <Form
        style={{
          width: "80%",
          margin: "0 auto",
          padding: "1rem",
        }}
      >
        {dialogMessage ? (
          <Card
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
            message={dialogMessage}
          />
        ) : (
          <>
            <TextInput placeholder="Your name" name="title" />
            <TextInput name="message" multiline />
            <Button>Send</Button>
          </>
        )}
      </Form>
    </Formik>
  );
};

const TextInput = ({ name, multiline, placeholder }) => {
  const [{ value }, , { setValue }] = useField(name);

  const additionalStyles = multiline ? { width: "100%" } : {};
  const additionalProps = multiline ? { rows: 15 } : {};
  const element = multiline ? "textarea" : "input";

  return React.createElement(element, {
    value,
    onChange: (e) => setValue(e.target.value),
    placeholder,
    style: {
      display: "block",
      border: "1px solid rgba(0,0,0,0.7)",
      borderRadius: 4,
      padding: "1rem",
      fontSize: "1.25rem",
      outline: "none",
      marginBottom: "1rem",
      backgroundColor: "rgba(255, 255, 255, 0.7)",
      fontFamily: "sans-serif",
      ...additionalStyles,
    },
    ...additionalProps,
  });
};

const Button = (props) => (
  <button
    // eslint-disable-next-line react/jsx-props-no-spreading
    {...props}
    type="submit"
    style={{
      position: "relative",
      backgroundColor: "rgba(0, 40, 83, 1)",
      border: "none",
      borderRadius: 4,
      overflow: "hidden",
      color: "white",
      letterSpacing: 1.2,
      padding: "0.75rem 1.5rem",
      fontSize: "1.25rem",
      marginLeft: "100%",
      transform: "translate(-100%, 0)",
      cursor: "pointer",
    }}
  />
);

export default PostSretanRodjendan;
